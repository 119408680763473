import { cyodetailStore }  from '../cyodetail/cyodetail';
import { cyoStore }  from '../cyo/cyo';
export default {
    setShape(data) {
        
      
        // const diamond =  this.getDiamond( data.moduleSubType )
        // if (diamond) {

        //     return
        // }   
        return this.commitMutation('setShape', data);
    },

    async editCyoFromCart(data){
        const storeDetail = cyodetailStore()
        const storeCyo = cyoStore()
        const localePath = useLocalePath();
        const router = useRouter()
        // try{
            const backendType = { cyor:'ring', cyop:'pendant', cyoe:'earring' }

            const moduleType = 'cyo'
            const moduleSubType = backendType[ data.type ]


            this.commitMutation('resetCyoState', moduleSubType)

            let currentSlug = ""

            if(data.type === 'cyoe'){
                currentSlug = data.slug
            }else{
                currentSlug = data.diamond.slug
            }

            // set diamond
            await storeDetail.setDiamondForDetailPage({ moduleSubType, slug: currentSlug })
            const diamond = storeDetail.getDiamondDetail()
            await storeCyo.setDiamond({moduleType,moduleSubType, item:{ ...diamond }  } )
            // end set diamond

            // set setting
            await storeDetail.setSettingForDetailPage({ moduleSubType, slug: data.setting.slug })
            await storeCyo.setSetting({moduleType,moduleSubType} )

            // end set setting

            if (moduleSubType == 'ring') {
                
                let prong_tip_color = data.prong_tip_color
                let prong_type = data.prong_type

                if(data.allow_prong === 0){
                    prong_tip_color = null
                    prong_type = null
                }

                const attrs =  {moduleSubType,prong_tip: prong_tip_color, prong_type:prong_type, size:data.selected_size }
                this.commitMutation('setSettingCustomAttributes',attrs)
            }

            // await vuexContext.dispatch('removeFromCart', data)

            router.push( localePath({ name: 'cyo-complete-'+moduleSubType }) )

        // }catch( error ){

        //     alert(  'could not process request')
        // }

        return

    },

    
    async testUserSessionWithSsr(  data ){
        const storeDetail = cyodetailStore()
        const moduleType = data.moduleType
        const moduleSubType = data.moduleSubType
        const vuexDiamond = this.getDiamond(moduleSubType)
        let vuexSetting = null
        const vuexSettingData = this.getSetting(moduleSubType)

        if (vuexSettingData && vuexSettingData.metal) {

            vuexSetting = vuexSettingData.metal
        }

        // if url param are absent then check session
        if( !data.sid || !data.did ){

            // if both are present, redirect with corect url param
            if ( vuexSetting && vuexDiamond ){
                return { status: true, error : 'from_vuex', url: { name: 'cyo-complete-'+moduleSubType, query: { sid:vuexSetting.id, did:vuexDiamond.id } } }

            }
            // if setting is absent then return to setting listing
            if(!vuexSetting){
                return { status: false, error : 'vuex_absent_id', url:{name: 'cyo-setting-list-'+moduleSubType} }
            }

            // if diamond is absent then return to diamond listing
            else if(!vuexDiamond){
                return { status: false, error : 'vuex_absent_id', url:{name: 'cyo-diamond-list-'+moduleSubType} }
            }
            // else return to setting list
            else{
                return { status: false, error : 'vuex_absent_id', url:{name: 'cyo-setting-list-'+moduleSubType} }
            }
        }

        if(( vuexSetting && vuexSetting.id != data.sid ) || ( vuexDiamond && vuexDiamond.id != data.did )){
            return { status: false, error : 'mismatch_session', session: { sid: vuexSetting.id, did:vuexDiamond.id, message : 'You have already selected a setting and a diamond. Would you like to update it' } }
        }
   
        // new user set session
        if( !vuexSetting && !vuexDiamond ){

            const diamond  =  storeDetail.getDiamondDetail(data.moduleSubType)

            this.commitMutation('setDiamond',  {moduleType:data.moduleType,moduleSubType:data.moduleSubType, item:{ ...diamond }  });
            await this.setSetting(data )
        }


        return { status: true, error : null }
    },
    
    async setCyoFromUrl( data){

      
        const localePath = useLocalePath();
        const route = useRoute()
        const router = useRouter()

        const storeDetail = cyodetailStore()
        const moduleType = data.moduleType
        const moduleSubType = data.moduleSubType
        

        
        if(!data.sid || !data.did){

            return
        }

        //get setting by id and validate response
        let settingRes
        let setting
        try {

            const settingUrl = useCountryPath(`/config/setting/${moduleSubType}/detailbyid/${data.sid}`)
            settingRes = await $fetch( settingUrl )
          
          

        }catch (error) {
           
            if( error.response?.status === 404 ){
                this.commitMutation('unsetSetting', moduleSubType)
                return router.push(localePath({name: 'cyo-setting-list-'+moduleSubType}));
            }

        }
        
        
        //get diamond  by id and validate response
        let diamond;
        let diamondRes
        try {

            let diamondUrl

            if(moduleSubType === 'earring'){
                diamondUrl = useCountryPath(`/diamond/loose-pair/detailbyid/${data.did}`)
            }else{
                diamondUrl = useCountryPath(`/diamond/detailbyid/${data.did}`)
            }
            
            diamondRes = await $fetch( diamondUrl )

        }catch (error) {
           
            if( error.response?.status === 404 ){
                this.commitMutation('unsetDiamond', moduleSubType)
                return router.push(localePath({name: 'cyo-diamond-list-'+moduleSubType}));
            }

        }
        
        //check if setting and diamond response is success
       
        setting = settingRes.data
    
        diamond = diamondRes.data
 
        
        // storeDetail.setDiamondForDetailPage(diamond)
        // storeDetail.setSettingForDetailPage(setting)

        storeDetail.commitMutation('setDiamondForDetailPage', diamond)
        storeDetail.commitMutation('setSettingForDetailPage', setting)

        
        if(  data.setSession ){

            const state = storeDetail.getSetSettingAttributes( data.moduleSubType )
            this.setSetting({ ...data, state: state })
            this.setDiamond({moduleType:moduleType,moduleSubType:moduleSubType, item:{ ...diamond }  });
        }
        
    },

    async updateSettingImage( data) {

        const setting = this.getSetting( data.moduleSubType )


        const shape = data.item.shape_one ? data.item.shape_one : data.item.shape

        if (setting && setting.avail_shapes.includes( shape ) ) {

            const path = setting.shape_images[ shape ]

            return this.commitMutation('changeSettingImagePath', { moduleSubType:data.moduleSubType,  path : path });
        }
    },

    async setDiamond( data) {

        const shape = data.item.shape_one ? data.item.shape_one : data.item.shape;

        if ( shape ) {
            
            this.setShape({ shape: {value:shape}, moduleSubType: data.moduleSubType } )

            this.updateSettingImage(data )
        }

        return this.commitMutation('setDiamond', data);
    },


    setSetting( data) {
        const storeDetail = cyodetailStore()
        const state = storeDetail.getSetSettingAttributes( data.moduleSubType )
        return this.commitMutation('setSetting', { ...data, state: state })
    },


    unsetSetting( moduleSubType) {

        this.commitMutation('unsetSetting', moduleSubType)
    },
    unsetDiamond( moduleSubType) {

        this.commitMutation('unsetDiamond', moduleSubType)

        this.setShape({ shape: {value:''}, moduleSubType: moduleSubType } )

        // this.commitMutation('setShape', { shape : { value : '' } });

    },

    async checkDiamondAvailability( data){
        
        let status = 0

        if (! this.getDiamond(data.moduleSubType) ) {

            return status
        }

        let url = useCountryPath('/diamond/detail/' + this.getDiamond(data.moduleSubType).slug)

        const res = await this.$axios.$get( url )

        if ( res.data ) {

            status = res.data.availability
        }
        return status;
    },

    async checkSettingAvailability( data){
        
        let status = 0

        // if setting is not selected then return 0
        if (! this.getSetting(data.moduleSubType) ) {

            return status
        }

        let url = useCountryPath('/setting/'+data.moduleSubType+'/detail/'+this.getSetting(data.moduleSubType).metal.slug)

        const res = await this.$axios.$get( url )

        if ( res.success === true ) {

            status = res.data.availability
        }
        return status;
    },

    async checkItemAvailibity( data) {

        let diamond, setting = 0

        if (data.moduleSubType === 'ring') {

           setting =  await this.dispatch('checkSettingAvailability', data)
           diamond =  await this.dispatch('checkDiamondAvailability', data)
        }


        if (data.moduleSubType === 'pendant') {

           setting =  await this.dispatch('checkSettingAvailability', data)
           diamond =  await this.dispatch('checkDiamondAvailability', data)
        }

        return {setting:setting, diamond:diamond }

    },
    
    async addToCart( data) {
        
        console.log(data, 'data')
        const params = {}

        params.loose_id = this.getDiamond(data.moduleSubType).id
        params.setting_id = this.getSetting(data.moduleSubType).metal.id


        if (data.moduleSubType === 'ring') {

            params.prong_tip_color = this.getFormData(data.moduleSubType).prong_tip

            params.prong_type = this.getFormData(data.moduleSubType).prong_type
        }

        if (data.moduleSubType === 'pendant') {

            params.chain_length = this.getFormData(data.moduleSubType).chain_length
        }

        if (data.moduleSubType === 'earring') {

            params.back_type = this.getFormData(data.moduleSubType).back_type
        }

        params.wedding_id = null
        params.wedding_band_size = null

        params.size = data.form.size

        if (data.moduleSubType === 'pendant') {
            params.chain_length = data.form.size
        }

        if (data.moduleSubType === 'earring') {
            params.back_type = data.form.size
        }

        params.inscription = data.form.inscription
        params.has_appraisal = data.form.appraisal ? 1 : 0

        let url = useCountryPath('/setting/' + data.moduleSubType + '/add/session')

        console.log(url, 'urlurlurlurl')

        // await this.$axios.$post(url, {...params })
        await $fetch(url, {
            method: 'POST',
            body:  {...params }
        })
       
        this.commitMutation('resetCyoState', data.moduleSubType)



        // this.getCartCount()

    }

}