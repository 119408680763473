import cyoDetailApiService from '~/services/api/cyoDetailApiService';


export default {

    // // setup filters
    // // call server for latest min max
    // // once min max is received then change it accordinglyv to url params
    // async resetSettingDetailState(vuexContext, data) {

    //     // this.commit( 'resetSettingFilterState' )
    // },

    async setSettingForCompletePage(data){
        const setting = this.getSetting( data.moduleSubType )

        if (setting && setting.metal) {
            
            this.setSettingForDetailPage({ ...data, slug : setting.metal.slug })
        }
        
        return null
    },

    async setDiamondForCompletePage(data){

        const diamond = this.getDiamond( data.moduleSubType )

        if (diamond) {

            this.setDiamondForDetailPage({ ...data, slug : diamond.slug })
        }
        
        return null
    },


	async setSettingForDetailPage(data){
        const route = useRoute()
        const router = useRouter()
        const localePath = useLocalePath()
        // console.log(app, 'apppppp')
        // const response = await fetch( url)
        //     const res = await response.json();

        try{
            
            // const res = await vuexContext.$axios.$get( url );
            const url = await cyoDetailApiService(this).setSettingForDetailPage(data)

            const res = await $fetch( url)
            // const res = await response.json();
            // this.commitMutation('setSettingForDetailPage', res.data)
            
            if (! res.data) {

                // throw vuexContext.app.context.error(  { message :'missing data property in response cyoDetailApiService', status : 500}  )
            }

            if ( res.data.slug !== data.slug ) {

                const rname = router.name

                const newPath = router.resolve( {name: rname, params : { slug : res.data.slug }} )
                
                if (import.meta.client && newPath.href ) {
                    return router.push( localePath( {name: rname, params : { slug : res.data.slug }} )  )
                }


                if ( import.meta.server && newPath.href ) {

                    return redirect( 302, localePath( newPath.href )  )


                }
                
            }
            this.commitMutation('setSettingForDetailPage', res.data)
            // return res.data

        }catch(error){
            console.log(error.response.status, 'errrrrrooorrrrrrrrrjj')
            if (error.response.status === 404 || error.response.status === 400) {
                console.log('hhhhhhhhhhhhhhhhhhhh')
                // throw createError(  { message :'product not found cyoDetailApiService', status : 404}  )
                throw createError({
                    statusCode: 404,
                    statusMessage: 'Page Not Found'
                  })
                // check if redirection exists
                try{
                    const queryParams = router.currentRoute.query
                    
                    const data = await $fetch(useCountryPath('/redirect'),  { params : { path :  router.path } } )

                    if (data.data?.url.new_url) {

                        
                        let url = data.data?.url.new_url
                        if(queryParams){
                            const query = Object.entries(queryParams).map(([key,value]) => `${key}=${value}`).join('&');
                            url = `${data.data?.url.new_url}?${query}`
                        }

                        redirect( 302, localePath(url))
                    }

                }catch( e2 ){

                    console.log( e2, 'this is e2' )
                }




                
            }

            throw error
        }
       
    },

    async unsetSettingForDetailPage(){

        this.commitMutation('unsetSettingForDetailPage')
    },


    async changeSettingAttribute(data){

        if (data.type === 'metals' && data.moduleSubType === 'ring' ) {

            this.commitMutation( 'changeSettingProngTipColorAttribute', data )
        }

        this.commitMutation( 'changeSettingAttribute', data )
    },

    // ----------------------diamond------------------------------------

    async setDiamondForDetailPage(data){
     
        const url = await cyoDetailApiService(this).requestDiamondForDetailPage(data)

        const callbackFn = (response) =>{

            // console.log('setDiamondForDetailPage', this.commitMutation)
            this.commitMutation('setDiamondForDetailPage', response)
        }

        

        // try {
        //     await useFetch( url )
            
        // } catch (error) {
            
        //     console.log('error', error)
        // }
        const response = await fetch( url)
        const json = await response.json();
      
        this.commitMutation('setDiamondForDetailPage', json.data)

        // console.log('setDiamondForDetailPage', r, import.meta.server, url,)

        // await $fetch( url, {
        //     async onResponse({ request, response, options }) {
                
        //         // console.log('setSettingForDetailPage', this.commitMutation)
        //         // callbackFn( response._data.data )
        //             // if( data.route?.query?.Shape && typeof data.route?.query?.Shape === 'string' ){

        //             //     this.setShape({ shape: {value:data.route?.query?.Shape.toLowerCase()}, moduleSubType: data.moduleSubType })
        //             // }
        //             // console.log('setSettingForDetailPage', response._data.data)

        //             // this.commitMutation('setDiamondForDetailPage', response._data.data)
                    
        //         // await callbackFn( response._data.data )
        //     }
        // } )



        // const res = await cyoDetailApiService(this).requestDiamondForDetailPage(data)
        
        // this.commit('setDiamondForDetailPage', res)
    },

    async unsetDiamondForDetailPage(){

        this.commit('unsetDiamondForDetailPage')
    },

}